<template>
    <default-template>
        <v-layout id="sub-wrap" justify-center align-start>
            <v-responsive width="100vw" max-width="1200" class="sub-contents">
                <div class="container">
                    <div class="sub_head_title clearfix">
                        <div class="fLeft">
                            <h1>대량거래/체인점문의</h1>
                        </div>
                    </div>
                    <div class="inquiry-section">
                        <div class="img-wrap"><img src="/images/pinbox/inquiry-img.png" alt="악수이미지"></div>
                        <p class="page-text"><strong class="color-orange">대량거래</strong> 또는 <strong
                                class="color-orange">사업</strong>에 관심이 있는 분은 <strong>언제든지 연락주세요</strong></p>
                        <div class="img-wrap mb-0"><img src="/images/connbook/inquiry-img2.png"
                                alt="대량거래시 수수료 우대. 콘앤북만의 특화된 사업모델 시스템을 제공해드립니다. 대량거래/체인점 선택 후 connbook@daum.net 이메일 접수">
                            <p>* 연락처 성함 필수이며 담당자 검토 후 24시간 이내 연락 드리겠습니다</p>
                        </div>
                    </div>
                    <div class="inquiry-section2">
                        <div class="inquiry-info">
                            <div class="inquiry-info-left"><img src="/images/connbook/ci.jpg" alt="핀박스" width="152"></div>
                            <div class="inquiry-info-right">
                                <p><strong>콘앤북</strong></p>
                                <p><em>대표번호</em>010-8020-5075</p>
                                <p><em>이메일</em>connbook@daum.net</p>
                            </div>
                        </div>
                    </div>
                </div>
            </v-responsive>
        </v-layout>
    </default-template>
</template>
<script>
import { mapState } from 'vuex';
import DefaultTemplate from "../DefaultTemplate.vue";
export default {
    components: {
        DefaultTemplate
    },
    computed: {
        ...mapState(["siteInfo"]),
    }
}
</script>


<style scoped>
img {display: block;margin: 0;border: none;max-width: 100%;}
/* ========== COMMON ========== */
.blind{ overflow: hidden; visibility: hidden; display: block; position: absolute; width: 0; height: 0; line-height: 0; font-size: 0; }
.quickmenu_container { margin: auto; max-width: 1500px; width: 100%; }



.mo_br, .mo-br { display: none; }

.sp { display: none; }
@media (max-width:768px) {
    .sp { display: block; }
    .pc { display: none; }
}


.bg-light { background-color: #f3f6f8; }
.bg-light2 { background-color: #fafafa; }
.bg-blue { background-color: #33abe6; }

.color-orange{color:#fca932;}


.button { display: inline-block; text-align: center; }
.button-45 { height: 45px; line-height: 45px; }
.button-45.button-round { border-radius: 23px; }
.button-50 { height: 50px; line-height: 50px; }
.button-50.button-round { border-radius: 25px; }
.button-55 { height: 55px; line-height: 55px; }
.button-55.button-round { border-radius: 28px; }
.button-60 { height: 60px; line-height: 60px; }
.button-60.button-round { border-radius: 25px; }
.button-65 { height: 65px; line-height: 65px; }
.button-65.button-round { border-radius: 33px; }
.button-70 { height: 70px; line-height: 70px; }
.button-70.button-round { border-radius: 25px; }
.button-point { color: #fff !important; background: #db1d27; }
.button-blue { color: #fff !important; background: #2a8ddc; }
.button-white { color: #333333 !important; background: #fff; }

@media (max-width: 1024px) {
    .button-45 { height: 40px; line-height: 40px; }
    .button-50 { height: 45px; line-height: 45px; }
    .button-55 { height: 50px; line-height: 50px; }
    .button-60 { height: 50px; line-height: 50px; }
    .button-65 { height: 60px; line-height: 60px; }
    .button-70 { height: 60px; line-height: 60px; }
}
@media (max-width: 768px) {
    .button-50 { height: 40px; line-height: 40px; }
    .button-55 { height: 40px; line-height: 40px; }
    .button-60 { height: 40px; line-height: 40px; }
    .button-65 { height: 40px; line-height: 40px; }
    .button-70 { height: 45px; line-height: 45px; }
}

.title-xsmall1{font-size:11px;}.title-xsmall2{font-size:12px;}.title-xsmall3{font-size:13px;}.title-xsmall4{font-size:14px;}.title-xsmall5{font-size:15px;}.title-xsmall6{font-size:16px;}.title-xsmall7{font-size:17px;}.title-xsmall8{font-size:18px;}.title-xsmall9{font-size:19px;}
.title-small{font-size:20px;}.title-small1{font-size:21px;}.title-small2{font-size:22px;}.title-small3{font-size:23px;}.title-small4{font-size:24px;}.title-small5{font-size:25px;}.title-small6{font-size:26px;}.title-small7{font-size:27px;}.title-small8{font-size:28px;}.title-small9{font-size:29px;}
.title-regular{font-size:30px;}.title-regular1{font-size:31px;}.title-regular2{font-size:32px;}.title-regular3{font-size:33px;}.title-regular4{font-size:34px;}.title-regular5{font-size:35px;}.title-regular6{font-size:36px;}.title-regular7{font-size:37px;}.title-regular8{font-size:38px;}.title-regular9{font-size:39px;}
.title-medium{font-size:40px;}.title-medium1{font-size:41px;}.title-medium2{font-size:42px;}.title-medium3{font-size:43px;}.title-medium4{font-size:44px;}.title-medium5{font-size:45px;}.title-medium6{font-size:46px;}.title-medium7{font-size:47px;}.title-medium8{font-size:48px;}.title-medium9{font-size:49px;}
.title-large{font-size:50px;}.title-large1{font-size:51px;}.title-large2{font-size:52px;}.title-large3{font-size:53px;}.title-large4{font-size:54px;}.title-large5{font-size:55px;}.title-large6{font-size:56px;}.title-large7{font-size:57px;}.title-large8{font-size:58px;}.title-large9{font-size:59px;}
.title-xlarge{font-size:60px;}.title-xlarge1{font-size:61px;}.title-xlarge2{font-size:62px;}.title-xlarge3{font-size:63px;}.title-xlarge4{font-size:64px;}.title-xlarge5{font-size:65px;}.title-xlarge6{font-size:66px;}.title-xlarge7{font-size:67px;}.title-xlarge8{font-size:68px;}.title-xlarge9{font-size:69px;}
@media(max-width:1024px){
.title-xsmall1{font-size:10px;}.title-xsmall2{font-size:11px;}.title-xsmall3{font-size:12px;}.title-xsmall4{font-size:13px;}.title-xsmall5{font-size:14px;}.title-xsmall6{font-size:15px;}.title-xsmall7{font-size:16px;}.title-xsmall8{font-size:17px;}.title-xsmall9{font-size:17px;}.title-small{font-size:18px;}.title-small1{font-size:18px;}.title-small2{font-size:18px;}.title-small3{font-size:18px;}.title-small4{font-size:18px;}.title-small5{font-size:18px;}.title-small6{font-size:18px;}.title-small7{font-size:18px;}.title-small8{font-size:18px;}.title-small9{font-size:18px;}.title-regular{font-size:20px;}.title-regular1{font-size:21px;}.title-regular2{font-size:22px;}.title-regular3{font-size:23px;}.title-regular4{font-size:24px;}.title-regular5{font-size:25px;}.title-regular6{font-size:26px;}.title-regular7{font-size:27px;}.title-regular8{font-size:28px;}.title-regular9{font-size:29px;}.title-medium{font-size:30px;}.title-medium1{font-size:31px;}.title-medium2{font-size:32px;}.title-medium3{font-size:33px;}.title-medium4{font-size:34px;}.title-medium5{font-size:35px;}.title-medium6{font-size:36px;}.title-medium7{font-size:37px;}.title-medium8{font-size:38px;}.title-medium9{font-size:39px;}.title-large{font-size:40px;}.title-large1{font-size:41px;}.title-large2{font-size:42px;}.title-large3{font-size:43px;}.title-large4{font-size:44px;}.title-large5{font-size:45px;}.title-large6{font-size:46px;}.title-large7{font-size:47px;}.title-large8{font-size:48px;}.title-large9{font-size:49px;}.title-xlarge{font-size:50px;}.title-xlarge1{font-size:41px;}.title-xlarge2{font-size:42px;}.title-xlarge3{font-size:43px;}.title-xlarge4{font-size:44px;}.title-xlarge5{font-size:45px;}.title-xlarge6{font-size:46px;}.title-xlarge7{font-size:47px;}.title-xlarge8{font-size:48px;}.title-xlarge9{font-size:49px;}
}
@media (max-width: 768px){
.title-xsmall5{font-size:13px;}.title-xsmall6{font-size:14px;}.title-xsmall7{font-size:15px;}.title-xsmall8{font-size:16px;}.title-xsmall9{font-size:16px;}.title-small{font-size:17px;}.title-small2{font-size:17px;}.title-small3{font-size:17px;}.title-small4{font-size:17px;}.title-small5{font-size:17px;}.title-small6{font-size:17px;}.title-small7{font-size:17px;}.title-small8{font-size:17px;}.title-small9{font-size:17px;}.title-regular{font-size:18px;}.title-regular2{font-size:18px;}.title-regular3{font-size:18px;}.title-regular4{font-size:18px;}.title-regular5{font-size:18px;}.title-regular6{font-size:18px;}.title-regular7{font-size:18px;}.title-regular8{font-size:18px;}.title-regular9{font-size:18px;}.title-medium{font-size:20px;}.title-medium1{font-size:21px;}.title-medium2{font-size:22px;}.title-medium3{font-size:23px;}.title-medium4{font-size:24px;}.title-medium5{font-size:25px;}.title-medium6{font-size:26px;}.title-medium7{font-size:27px;}.title-medium8{font-size:28px;}.title-medium9{font-size:29px;}.title-large{font-size:20px;}.title-large1{font-size:21px;}.title-large2{font-size:22px;}.title-large3{font-size:23px;}.title-large4{font-size:24px;}.title-large5{font-size:25px;}.title-large6{font-size:26px;}.title-large7{font-size:27px;}.title-large8{font-size:28px;}.title-large9{font-size:29px;}.title-xlarge{font-size:30px;}.title-xlarge1{font-size:21px;}.title-xlarge2{font-size:22px;}.title-xlarge3{font-size:23px;}.title-xlarge4{font-size:24px;}.title-xlarge5{font-size:25px;}.title-xlarge6{font-size:26px;}.title-xlarge7{font-size:27px;}.title-xlarge8{font-size:28px;}.title-xlarge9{font-size:29px;}
}

.page-text{
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
}
.page-text strong{
    font-weight: 600;
}
@media(max-width:992px){
    .page-text{
        font-size: 28px;
        margin-bottom: 10px;
    }
}
@media(max-width:768px){
    .page-text{
        font-size: 24px;
    }
}
@media(max-width:576px){
    .page-text{
        font-size: 20px;
    }
}



.m-scroll { display: none; margin: auto; max-width: 360px; width: 100%; }
[data-scroll="false"] { overflow-y: hidden; }

.clearfix:after { content: ''; display: table; clear: both; }
ul.list:after { content: ''; display: table; clear: both; }
ul.list > li { float: left; }
.fLeft { float: left; }
.fRight { float: right; }
.aRight { text-align: right; }

.mb-0{margin-bottom: 0 !important;}


/* ------- Grid ------- */
.grid{display:flex;flex-wrap:wrap;}
.grid > div,.grid > li{padding:10px;}
.grid-24{width:100%;}.grid-12{width:50%;}.grid-8{width:33.333333%;}.grid-6{width:25%;}.grid-5{width:20%;}.grid-4{width:16.66666%;}
@media (max-width: 1024px){.grid-4{width:33.33333%;}}
@media(max-width:768px){.grid-6{width:50%;}.grid-5{width:33.33333%;}}
@media(max-width:600px){.grid-8,.grid-5,.grid-4{width:50%;}}





.img { position: relative; }
.img:after { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: transparent; }
.img-wrap{
    text-align: center;
}
.img-wrap img{
    margin: auto;
}


.imgz { display: none; text-align: center; font-size: 13px; }
.imgz:before { content: ''; display: inline-block; vertical-align: -12px; margin-right: 2px; width: 35px; height: 35px; background: url('/child/img/icon/zoom.png') no-repeat center / 35px auto; }
.imgz:after { content: 'ì´ë¯¸ì§€ë¥¼ í´ë¦­í•˜ì‹œë©´ í¬ê²Œ ë³¼ ìˆ˜ ìžˆìŠµë‹ˆë‹¤.'; }
@media (max-width:768px) {
    .imgz { display: block; }
}





.m-scroll-area {
    position: relative;
}
.scroll { overflow-x: auto; z-index: 2; position: relative; }
@media (max-width:1200px) {
    .scroll-b:before{
        content: ''; z-index: 3; position: absolute; top: 0; right: 0;
        width: 15px; height: 100%;
        background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%);
        background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%);
        background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#1a000000',GradientType=1 );
    }
    .scroll-b .scroll .inner { min-width: 1200px; }
}
@media (max-width:1024px) {
    .scroll-t:before{
        content: ''; z-index: 3; position: absolute; top: 0; right: 0;
        width: 15px; height: 100%;
        background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%);
        background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%);
        background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#1a000000',GradientType=1 );
    }
    .scroll-t .scroll .inner { min-width: 900px; }
}

@media (max-width:550px) {
    .scroll-m:before{
        content: ''; z-index: 3; position: absolute; top: 0; right: 0;
        width: 15px; height: 100%;
        background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%);
        background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%);
        background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#1a000000',GradientType=1 );
    }
    .scroll-m .scroll .inner { min-width: 550px; }
}

@media (max-width:425px) {
    .scroll-s:before{
        content: ''; z-index: 3; position: absolute; top: 0; right: 0;
        width: 15px; height: 100%;
        background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%);
        background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%);
        background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#1a000000',GradientType=1 );
    }
    .scroll-s .scroll .inner { min-width: 425px; }
}



.slick-prev { overflow: hidden; text-indent: -99999px; z-index: 2; position: absolute; top: 0; bottom: 0; left: 0; margin: auto; }
.slick-next { overflow: hidden; text-indent: -99999px; z-index: 2; position: absolute; top: 0; bottom: 0; right: 0; margin: auto; }
.slick-slide:focus { outline: none; }
.slick-initialized .slick-slide { overflow: hidden; }
.slick-dots { position: absolute; left: 0; bottom: 50px; width: 100%; text-align: center; font-size: 0; }
.slick-dots li { overflow: hidden; text-indent: -999999px; display: inline-block; vertical-align: top; margin: 0 5px; cursor: pointer; }


.float:after { content: ''; display: block; clear: both; }
.f-left { float: left; }
.f-right { float: right; }


.centre { display: table; width: 100%; height: 100%; }
.centre .inner { display: table-cell; vertical-align: middle; width: 100%; height: 100%; }



/* List Style */
.ul-square { margin-left: 15px; list-style: square; }
.ul-square li { list-style: square; list-style-image: url('/child/img/icon/square.png'); }




/* Tab Menu */
.tab-menu { }
.tab-menu:after { content: ''; display: block; clear: both; }
.tab-menu-2 > li { width: 50%;  }
.tab-menu > li { float: left; }
.tab-menu > li a,
.tab-menu > li button{
    display: block; position: relative;
    width: 100%; height: 55px;
    text-align: center; color: #aaaaaa; font-size: 15px; letter-spacing: -0.04em;
}
.tab-menu > li button:before { content: ''; position: absolute; bottom: 0; left: 0; width: 100%; height: 1px; background: #0096e0; }
.tab-menu > li button[aria-pressed="true"] { color: #fff; background: #0096e0; }
.tab-menu > li button[aria-pressed="true"] strong { color: #fffdb9; }
.tab-contents { position: relative; }
.tab-contents > li { display: none; }
.tab-contents > li.on {
    display: block;
    animation: content-on ease-out .65s forwards;
}
@-webkit-keyframes contents-on {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes contents-on {
    from { opacity: 0; }
    to { opacity: 1; }
}





/* Tab Menu 2 Depth */
.tab_menu_2depth > ul > li { float: left; width: 50%; }
.tab_menu_2depth > ul > li > a { display: block; padding-left: 20px; height: 45px; line-height: 45px; color: #888888; text-align: left; font-size: 14px; font-weight: 400; background: url('/child/img/tabmenu/sub_tap.png') no-repeat right 15px center; border: 1px solid #e5e5e5; }
.tab_menu_2depth > ul > li:nth-child(2) > a { border-left: none; }
.tab_menu_2depth > ul > li:nth-child(3) > a { border-left: none; }
.tab_menu_2depth > ul > li:last-child > a { border-left: none; }
.tab_menu_2depth > ul > li.active > a { color: #fff; background: #1e458f url('/child/img/tabmenu/sub_tap_on.png') no-repeat right 15px center; border: none; }
.tab_contents_2depth > ul > li { display: none; text-align: left; }
.tab_contents_2depth > ul > li.active { display: block; }




/* Button Style */
.btn, a.btn { display: inline-block; vertical-align: middle; width: 100px; height: 50px; line-height: 50px; text-align: center; font-size: 16px; }
input.btn { padding-left: 0; }
.btn_border { background-color: #fff; border: 1px solid #eee; }
.btn_dark_border { background-color: #fff; border: 1px solid #ddd; }
.btn_confirm, a.btn_confirm { background-color: #666; color: #fff; }
.btn_point, a.btn_point { background-color: #333; color: #fff; }
.btn_point2, a.btn_point2 { background-color: #3e9b93; color: #fff; }
.btn_dark, a.btn_dark { background: #999; }




/* ======= Accordon Style ======= */
#menu-wrapper { overflow: hidden; max-width: 100%; }
#menu-wrapper #hamburger-menu { position: relative; width: 25px; height: 20px; margin: 15px; }
#menu-wrapper #hamburger-menu span { opacity: 1; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; left: 0; display: block; width: 100%; border-radius: 10px; color: black; background-color: white; position: absolute;
    height: 2px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: ease-out .4s;
    transition: ease-out .4s; }
#menu-wrapper #hamburger-menu span:nth-child(1) { top: 0; }
#menu-wrapper #hamburger-menu span:nth-child(2) { top: 9px; }
#menu-wrapper #hamburger-menu span:nth-child(3) { top: 18px; }
#menu-wrapper #hamburger-menu.open span:nth-child(1) { top: 9px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}
#menu-wrapper #hamburger-menu.open span:nth-child(2) { opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; left: -60px; }
#menu-wrapper #hamburger-menu.open span:nth-child(3) { top: 9px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}
#menu-container { overflow-y: auto; height: 100%; }
#menu-container .menu-list { padding-left: 0; display: block; width: 100%; background: white; overflow-y: auto; overflow-x: hidden; }
#menu-container .menu-list li.accordion-toggle, #menu-container .menu-list .menu-login {
    font-size: 15px;
    text-transform: uppercase;
/*    border-top: 1px solid #dbdcd2;*/
}
#menu-container .menu-list li:first-of-type { border-top: 0; }
.accordion-toggle, .accordion-content { font-size: 16px; position: relative; letter-spacing: 1px; }
.accordion-content { display: none; }
.accordion-toggle a:before, .accordion-toggle a:after { content: ''; display: block; position: absolute; top: 50%; right: 30px; width: 15px; margin-top: -1px;
     background-color: #333; height: 1px;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: ease-out 0.35s;
    transition: ease-out 0.35s; }
.accordion-toggle a:before {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 1; z-index: 2; }
.accordion-toggle a.active:before {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    background: #333 !important; }
.accordion-toggle a.active:after {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    background: #333 !important; opacity: 0; }



/* Page Style */
#page_list_style { margin: 30px 0 50px; }
.Paging_Num { margin: auto; width: auto; border-bottom: none !important; }
.Paging_Num tr td { border-bottom: none !important; }

#pager td { padding: 0; width: 30px; height: 30px; line-height: 30px; text-align: center; font-size: 14px; }
#pager td a { display: block; padding-top: 1px; width: 100%;  height: 100%; }
#pager td a:hover { padding-top: 0; background-color: #f8f8f8; border: 1px solid #cccccc; }
.current_page { color: #fff; background: #1e458f; border: 1px solid #1e458f; cursor: default; }
.page_backward { background: url('/child/img/pager/page_l_2.png') no-repeat center;  }
.page_back5 { background: url('/child/img/pager/page_l.png') no-repeat center; }
.page_5 { background: url('/child/img/pager/page_r.png') no-repeat center; }
.page_forward { background: url('/child/img/pager/page_r_2.png') no-repeat center; }


/* Table Responsive Cell */
.m_cell { display: none; }


/* BBS Write */
#bbs_write_style { border-top: 2px solid #1e458f; border-bottom: 2px solid #1e458f; }
#bbs_write_style tr th { padding: 10px; text-align: left; background-color: #fafafa; border-bottom: 1px solid #eee; }
#bbs_write_style tr td { padding: 10px; text-align: left; border-bottom: 1px solid #eee; }
#bbs_write_style tr td input[type=text],
#bbs_write_style tr td input[type=password] { max-width: 300px; width: 100%; height: 35px; }
#bbs_write_style tr td input[type=file] { max-width: 200px; width: 100%; }
#bbs_write_style tr td img { display: inline-block; }
.AWbbs_input_table tr td img { display: inline-block; }





/* Form mail Style */
.formmail-table { display: table; margin-bottom: 50px; width: 100%; border-collapse: collapse; }
.formmail-table .row { display: table-row; border-bottom: 1px solid #e5e5e5; }
.formmail-table .th { display: table-cell; vertical-align: middle; padding: 28px 30px 30px; width: 250px; font-size: 15px; color: #555555; font-weight: 500; word-break: keep-all; }
.formmail-table .td { display: table-cell; vertical-align: middle; padding: 10px 0; font-size: 15px; color: #555555; }
.formmail-table .td textarea { padding: 20px; width: 100%; font-size: 15px; color: #555555; background: #fff; }
.formmail-table .td input[type="text"],
.formmail-table .td select { max-width: 330px; width: 100%; height: 50px; font-size: 15px; background: #fff; border: 1px solid #e5e5e5; }
.formmail-table .td p { margin-top: 5px; color: #999999; }
.formmail-table .td b { padding-left: 10px; font-weight: 500; }
._w { max-width: 319px; }
.txt1 { height: 130px; }
.txt2 { height: 275px; }
.radio { display: inline-block; vertical-align: top; position: relative; }
.radio:last-child { margin-right: 0; }
.radio input { position: absolute; top: 0; left: 0; opacity: 0; visibility: hidden; }
.radio i { display: inline-block; vertical-align: middle; margin-right: 10px; width: 20px; height: 20px; background: url('/child/img/icon/radio.png') no-repeat center; }
.radio-light i { background: url('/child/img/icon/radio-light.png'); }
.radio input:checked ~ i { background: url('/child/img/icon/radio-on.png') no-repeat center; }
.radio input:checked ~ span { color: #333; }

.check { display: inline-block; vertical-align: top; position: relative; }
.check:last-child { margin-right: 0; }
.check input { position: absolute; top: 0; left: 0; opacity: 0; visibility: hidden; }
.check i { display: inline-block; vertical-align: -4px; margin-right: 5px; width: 25px; height: 21px; background: url('/child/img/icon/check.png') no-repeat left center; }
.check-light i { background: url('/child/img/icon/check-light.png') no-repeat left center; }
.check input:checked ~ i { background: url('/child/img/icon/check-on.png') no-repeat left center; }
.check input:checked ~ span { color: #333; }

.formmail-table .radio { margin-right: 50px; }
.formmail-table .check { margin-right: 50px; }

.formmail-table .td .input-file { position: relative; padding-right: 110px; }
.formmail-table .td .input-file [type="file"] { position: absolute; width: 1px; height: 1px;clip: rect(0, 0, 0, 0); border: 0 none; }
.formmail-table .td .input-file .file-label {
  display: inline-block; vertical-align: top;
  position: absolute; top: 0; right: 0; padding: 0 25px;
  width: 110px; height: 50px; line-height: 50px;
  color: #fff; text-align: center; font-size: 15px;
  background: #555555; border: 1px solid #222;
}
.formmail-table .td .input-file .file-name { max-width: 100%; width: 100%; height: 50px; line-height: 50px; text-indent: 5px; border: 1px solid #ddd; }
.formmail-table .td .file-focus { outline: 1px dotted #d2310e; }

.tel-box { display: inline-block; vertical-align: middle; margin-left: -1px; width: 100px; }
.tel-box ~ span { display: inline-block; vertical-align: middle; padding: 0 5px; font-size: 16px; }
.email-box-wrap,
.tel-box-wrap { font-size: 0; }
.email-box { display: inline-block; vertical-align: middle; width: 32%; }
.email-box ~ span { display: inline-block; vertical-align: middle; padding: 0 5px; text-align: center; font-size: 16px; width: 4%; }
.email-box:last-child { padding-left: 5px; }

.addr-box { margin-top: 10px; }
.addr1 { position: relative; margin-top: 0; padding-right: 110px; max-width: 330px; }
.addr1 button { position: absolute; top: 0; right: 0; width: 110px; height: 50px; font-size: 16px; color: #fff; background: #777777; }
.center-form .addr-box input { max-width: 100%; }

.form-title { padding-bottom: 10px; line-height: 20px; font-size: 20px; color: #333333; font-weight: 500; border-bottom: 1px solid #aaaaaa; }
.agree-title { border-bottom: none; }
.agree-wrap .check { font-size: 16px; color: #333; font-weight: 500; }
.form-title .check { float: right; }

.agree-wrap textarea,
.agree-wrap .textarea { overflow-y: auto; padding: 20px; width: 100%; height: 158px; font-size: 15px; background: #fff; border: 1px solid #dddddd; }

.agree-link-wrap { padding: 30px 15px; text-align: center; border: 5px solid #fceade; }
.agree-link-wrap span { font-weight: 500; }
.agree-link-wrap a { display: inline-block; margin-left: 20px; }
.agree-link-wrap a i { display: inline-block; vertical-align: 0; margin-left: 10px; width: 7px; height: 13px; background: url('/child/img/icon/agree-link-arrow.png'); }

.form-btn-wrap { margin-top: 30px; text-align: center; }
.form-btn { display: inline-block; vertical-align: top; padding: 5px; }
.form-btn button,
.form-btn a { width: 150px; font-size: 15px; }

.form-caption { display: block; margin-top: 10px; }





/* Scroll Top Button */
.scroll-top { z-index: 999; position: fixed; bottom: 30px; right: 30px; }
.scroll-top-btn { display: block; width: 60px; height: 60px; background: #db1d27 url('/child/img/icon/scroll-top.gif') no-repeat center / auto 70%; border-radius: 50%; box-shadow: 0px 2px 10px 0px rgba(0,0,0,.35); opacity: 0; visibility: hidden;
    -webkit-transform: translateY(150px);
    -ms-transform: translateY(150px);
    transform: translateY(150px);
}
[data-state="true"] { opacity: 1; visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: ease-out .25s;
    -o-transition: ease-out .25s;
    transition: ease-out .25s;
}




/* Fade */
.fade {
    -webkit-animation: fade 1s ease-out;
    animation: fade 1s ease-out;
}
@-webkit-keyframes fade {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes fade {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}



@media (max-width: 1024px){

    /* Button Style */
    .btn, a.btn { line-height: 45px; height: 45px; font-size: 15px !important; }
    .kcp-button{width:100%;}
    /* Form Style */
    .formmail-table .th { display: block; padding: 15px 0 5px; width: 100%; font-size: 14px; border-right: none !important; }
    .formmail-table .td { display: block; padding: 5px 0 15px; width: 100%; font-size: 14px; }
    .formmail-table .td input[type="text"],
    .formmail-table .td select { height: 40px; font-size: 14px; }
    .email-box ~ span { width: 10%; text-align: center; }
    .email-box,
    .tel-box { width: 30%; }
    .tel-box ~ span { width: 5%; text-align: center; }
    .email-box:last-child { padding-left: 5px; }
    .addr1 button { height: 40px; font-size: 14px; }

    .product_order_box{padding:0px;}
    .order_left{padding:0px !important}
    .order_right{padding:0px !important}
    .order-side-bottom{padding:0px !important}
}




@media (max-width: 768px){
    .m-scroll { display: block; }
    .mo_br, .mo-br { display: block; }

    /* Button Style */
    .btn, a.btn { line-height: 40px !important; height: 40px !important; font-size: 14px !important; }

    /* Scroll Top Button */
    .scroll-top { right: 15px; bottom: 15px; }
    .scroll-top-btn { width: 50px; height: 50px; }

}


@media (max-width: 600px){
    /* Form Style */
    .form-btn { width: 50%; }
    .form-btn button,
    .form-btn a { width: 100%; }
    .form-btn-full .form-btn { width: 100%; }
}
@media (max-width: 550px){
    .email-box { width: 45%; }
    .email-box:last-child { padding: 5px 0 0; width: 100%; }
}




/* ì•„ì´ë”” ì¤‘ë³µí™•ì¸ ----------------------*/
.id_check_tit{position:relative; height:38px; line-height:38px; font-size:16px; color:#fff; font-weight:500; letter-spacing:-0.05em; padding-left:15px; background:#aaa;}
.id_check_tit a.close{position:absolute; right:10px; top:50%; margin-top:-10px;}

.id_check_cont{text-align:center;}
.id_check_cont p{margin:30px 0 0; font-size:13px; color:#666;}
.id_check_cont .id_search{background:#f7f7f7; border:1px solid #ddd; padding:15px 0; width:90%; margin:5%;}
.id_check_cont .id_search input{width:150px; height:45px;  background:#fff; border:1px solid #ddd; padding:0 0 0 5px; color:#333; margin:0 3px 0 10px;}
.id_check_cont .id_search button{ width: 80px; height: 45px; background: #555; color: #fff; }
.id_check_cont .id_result{text-align:center; font-size:13px; color:#333; line-height:1.6;}
.id_check_cont .id_result b{font-weight:600; color:#ff1d1d;}



@media (max-width: 1024px){
.id_check_tit{width:100%; font-size:1em !important;}
.id_check_cont{width:100%;}
.id_check_cont p{margin:30px 0 0; font-size:1em; color:#666;}
.id_check_cont .id_result{text-align:center; font-size:25px; color:#333; line-height:1.6;}


}


/* Wrap - Container */
#sub-wrap { padding: 90px 0 0; }
.container { margin: auto; max-width: 1050px; }
.container-45 { margin: auto; max-width: 1095px; }
.store-contents { padding: 0px 0 110px; }
.sub-contents { padding: 0px 0 150px; }
.store-wrap { padding: 150px 0 100px; }

.mt-none { margin-top: 0 !important; }
.mb-none { margin-bottom: 0 !important; }
.pt-none { padding-top: 0 !important; }
.pb-none { padding-bottom: 0 !important; }

@media (max-width: 1024px){
    #wrap { padding: 50px 0 0; }
    #sub-wrap { padding: 50px 0 0; }
    .container { padding-left: 15px; padding-right: 15px; }
    .store-wrap { padding: 110px 0 40px; }
    .sub-contents { padding: 45px 0 100px; }
    .store-contents { padding: 45px 0 100px; }
}
@media (max-width: 768px){
    .sub-contents {
        padding: 30px 0 70px;
    }
}

/* 마이페이지 */
.my-contents { padding-top: 30px; }
.my:after { content: ''; display: block; clear: both; }
.my-l { float: left; width: 23.8%; padding-right: 60px; }
.my-r { float: left; width: 76.2%; }
.my-ul li a { display: block; padding: 0 10px; height: 40px; line-height: 40px; font-size: 15px; color: #666666; }
.my-ul li.on a,
.my-ul li a:hover { color: #454e6c; font-weight: 500; background: url('/child/img/icon/my-on.png') no-repeat right 15px center; }

.head_title { padding: 12px 12px 12px 40px; text-align: left;background: url('/child/img/icon/icon_title.png') no-repeat center left / 22px; background-position-x: 8px; }
.head_title h3 { margin-bottom: 0; font-size: 18px; line-height: 23px; color: #333333; font-weight: 500; }

.sub_head_title {
    position: relative;
    line-height: 28px;
    padding-bottom: 20px;
    margin-bottom: 40px;
}
.sub_head_title .fRight { display: none; }
.sub_head_title .fLeft{
    float: none;
    display: block;
    text-align: center;
}
.sub_head_title h1 {
    font-size: 26px;
    font-weight: 600;
    color: #db1d27;
}
.sub_head_title h1:before{
    content:'';
    position: absolute;
    bottom:0;
    left:50%;
    transform: translate(-50%,0);
    display:block;
    width:8px;
    height:1px;
    background-color: #db1d27;
}
.sub_head_title .list { margin-top: 8px; }
.sub_head_title .list a { padding-right: 20px; background: url('/child/img/icon/icon_sub_arrow.png') no-repeat center right 5px; cursor: default; }
.sub_head_title .list a span { font-size: 14px; color: #666666; }
.sub_head_title .list a:first-child span { margin-top: 3.5px; display: inline-block; vertical-align: ㅡㅑㅇ읻; width: 16px; height: 13px; font-weight: 400; /*background: url('/child/img/icon/icon_sub_nav.png') no-repeat center;*/ }
.sub_head_title .list a:last-child { padding-right: 0; background: none; }

@media (max-width: 1024px){
    .head_title { background-size: auto 20px; }
    .head_title h3 { font-size: 16px; }
    .sub_head_title { margin-top: 10px; padding-bottom: 10px; line-height: 24px; }
    .sub_head_title .fLeft { padding-left: 0; }
    .sub_head_title h1 { font-size: 16px; }
    .sub_head_title .fRight { display: none; }
    .join_input_tit_wrap h3 { font-size: 16px; }


    .my-l { display: none; }
    .my-r { width: 100%; padding-left: 0; }
    .s-top-text h4 { line-height: 29px; font-size: 22px; }
    .s-top-text p { font-size: 14px; }
    .s-top-text a { font-size: 14px; }
}
@media (max-width: 768px){
    .sub_head_title{margin-bottom: 20px;}
}



/* ëŒ€ëŸ‰ê±°ëž˜/ì²´ì¸ì ë¬¸ì˜ */
.inquiry-section{
    padding-bottom: 70px;
    margin-bottom: 70px;
    border-bottom:1px solid #ddd;
}
.inquiry-section .img-wrap{
    margin-bottom: 80px;
}
.inquiry-section .page-text{
    margin-bottom: 90px;
}
.inquiry-section .img-wrap >p{
    font-size: 16px;
    color: #333;
    margin-top: 30px;
}
.inquiry-info{
    display: flex;
    flex-wrap: wrap;
}
.inquiry-info-left,
.inquiry-info-right{
    width:50%;
}
.inquiry-info-left{
    display: flex;
    align-items: center;
    padding-right: 80px;
}
.inquiry-info-left img{
    margin-left: auto;
}
.inquiry-info-right p{
    font-size: 16px;
    color: #333;
    line-height: 1.4;
    margin-bottom: 5px;
}
.inquiry-info-right p >strong{
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    padding: 3px 8px;
    color: #555;
    background-color: #f5f5f5;
    margin-bottom: 10px;
}
.inquiry-info-right p >em{
    display: inline-block;
    min-width: 75px;
    font-style: normal;
    font-weight: 600;
}
@media(min-width:1025px){
}
@media(max-width:1024px){
}
@media(max-width:992px){
    .inquiry-section{
        padding-bottom:30px;
        margin-bottom: 30px;
    }
    .inquiry-section .img-wrap{
        margin-bottom: 50px;
    }
    .inquiry-section .img-wrap >p{
        font-size: 14px;
    }    
    .inquiry-section .page-text{
        margin-bottom: 50px;
    }
}
@media(max-width:768px){
    .inquiry-section .img-wrap{
        margin-bottom: 30px;
    }
    .inquiry-section .page-text{
        margin-bottom: 30px;
    }
    .inquiry-info-left,
    .inquiry-info-right{
        width:100%;
    }
    .inquiry-info-left{
        padding: 0;
        margin-bottom: 20px;
    }
    .inquiry-info-left img{
        margin:auto;
    }
    .inquiry-info-right{
        text-align: center;
    }
}
@media(max-width:576px){
}
</style>